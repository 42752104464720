<template>
  <div id="cos-add">
    <el-form label-width="80px">
      <el-form-item label="文件名称">
        <el-input v-model="fileName"></el-input>
      </el-form-item>
      <el-form-item>
        <input name="myFile" type="file" @change="getFile" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onAddCos">立即创建</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  // addCos,
  // COS_STS_URL,
  // getBucketRegion,
  // getCosHolder
  cosApi,
} from "@/api/cosApi.js";
import COS from "cos-js-sdk-v5";
import { alertMessage } from "@/util/alertMessageUtil.js";

export default {
  data() {
    return {
      uploadFiles: null,
      fileName: "",
      bucket: "",
      region: "",
    };
  },
  mounted: function() {
    // 获取bucket 和 region
    cosApi
      .getBucketRegion("private")
      .then((ret) => {
        console.log("getBucket", ret);
        let data = ret.data;
        this.bucket = data.bucket;
        this.region = data.region;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    // 保存cos
    onAddCos() {
      if (this.fileName === "") {
        this.$message.error("名称不可为空");
        return;
      }
      if (!this.uploadFiles) {
        this.$message.error("没选中文件...");
        return;
      }
      var cosClient = new COS({
        getAuthorization: function(options, callback) {
          var url = cosApi.COS_STS_URL;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function(e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {
              console.log(e);
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              ExpiredTime: data.expiredTime,
            });
          };
          xhr.send();
        },
      });
      let file = this.uploadFiles[0];
      console.log("...file", file);
      // 分片上传文件
      let _this = this;
      cosClient.sliceUploadFile(
        {
          Bucket: this.bucket,
          Region: this.region,
          Key: this.fileName,
          Body: file,
          onTaskReady: function(taskId) {
            /* 非必须 */
            console.log(taskId);
          },
          onHashProgress: function(progressData) {
            /* 非必须 */
            console.log(JSON.stringify(progressData));
          },
          onProgress: function(progressData) {
            /* 非必须 */
            console.log(JSON.stringify(progressData));
          },
        },
        function(err, data) {
          console.log("...", err, data);
          if (data.statusCode == 200) {
            console.log("after upload...", data);
          }
        }
      );
    },
    onCancel() {},
    // 选择了文件
    getFile(e) {
      this.uploadFiles = e.target.files;
      this.fileName = this.uploadFiles[0].name;
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
#cos-add {
  margin: 20px;
  width: 60%;
  min-width: 600px;
}

.showFileCover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.showFileCover :hover {
  cursor: pointer;
}

.cosFileName {
  border-bottom: 1px solid blue;
}

.cosFileName :hover {
  cursor: pointer;
}

.showFile {
  width: 320px;
  height: 320px;
}
</style>
